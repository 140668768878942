<template>
  <YLayoutBodyMenu aside-width="8rem" :menu="[
       {title:'数据概览',path:'/shop/plugin/privilege-card/dashboard'},
       {title:'基础设置',path:'/shop/plugin/privilege-card/rule',children:[
            {title:'免单卡规则',path:'/shop/plugin/privilege-card/rule/card'},
            {title:'商家规则',path:'/shop/plugin/privilege-card/rule/merchant'},
            {title:'代理规则',path:'/shop/plugin/privilege-card/rule/agent'},
            {title:'免单海报',path:'/shop/plugin/privilege-card/rule/poster'},
            {title:'卡券海报',path:'/shop/plugin/privilege-card/rule/coupon-poster'},
            {title:'平台管理员',path:'/shop/plugin/privilege-card/rule/manage'},
       ]},
       {title:'免单卡页面',path:'/shop/plugin/privilege-card/conf',children:[
           {title:'基础内容',path:'/shop/plugin/privilege-card/conf/edit'},
           {title:'权益图片',path:'/shop/plugin/privilege-card/conf/rights'},
           {title:'轮播图',path:'/shop/plugin/privilege-card/conf/banner'},
           {title:'公告',path:'/shop/plugin/privilege-card/conf/notice'},
           {title:'个人中心轮播',path:'/shop/plugin/privilege-card/conf/page-mine-banner'},
       ]},
       {title:'商家分类',path:'/shop/plugin/privilege-card/merchant-cate'},
       {title:'商家管理',path:'/shop/plugin/privilege-card/merchant',children: [
            {title:'入驻中',path:'/shop/plugin/privilege-card/merchant/manage'},
            {title:'已停用',path:'/shop/plugin/privilege-card/merchant/stop'},
       ]},
       // {title:'卡券管理',path:'/shop/plugin/privilege-card/coupon'},
       {title:'核销数据',path:'/shop/plugin/privilege-card/coupon'},
       {title:'订单管理',path:'/shop/plugin/privilege-card/order'},
       {title:'免单会员',path:'/shop/plugin/privilege-card/user'},
       {title:'代理商',path:'/shop/plugin/privilege-card/agent'},
       {title:'区域代理',path:'/shop/plugin/privilege-card/area-agent'},
       {title:'批发商',path:'/shop/plugin/privilege-card/wholesaler',children: [
           {title:'基础配置',path:'/shop/plugin/privilege-card/wholesaler/conf'},
           {title:'规则配置',path:'/shop/plugin/privilege-card/wholesaler/mode-rule'},
           {title:'批发商管理',path:'/shop/plugin/privilege-card/wholesaler/user'},
           {title:'激活卡订单',path:'/shop/plugin/privilege-card/wholesaler/order'},
       ]},
   ]">
    <router-view/>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>